@import "../variables.scss";

#intro {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    background-color: var(--background);
    font-size: 1.5em;
    gap: 1em;
    z-index: 10;
    transition: opacity 1s;

    &.finished {
        opacity: 0;
        pointer-events: none;
    }

    #mute-button {
        position: absolute;
        bottom: 1em;
        right: 1em;
    }

    #skip-button {
        position: absolute;
        bottom: 1em;
        left: 50%;
        font-size: 0.8em;
    }

    #play {
        background-color: var(--foreground);
        color: inherit;
        border: none;
        padding: 0.5em 1em;
        border-radius: 0.5em;
        font-size: x-large;
        letter-spacing: 0.1em;
        cursor: pointer;
        outline: none;
        transition-property: box-shadow,padding, letter-spacing;
        transition-duration: 0.3s;

        &:active {
            border-color: var(--primary-color);
            border-width: 0.1em;
            border-style: solid;
        }

        &:hover {
            background-color: var(--background);
            border-color: var(--primary-color);
            border-width: 1px;
            border-style: solid;
            letter-spacing: 0.2em;
            box-shadow: 
            0 0 3em 0em var(--primary-color),
            0 0 6em 0em rgba(var(--primary-color-darker-rgb),0.5);
            padding: 0.5em 2em;
        }

        @keyframes fadeOut {
            to {
                opacity: 0;
                padding: 1em 3em;
                box-shadow: none;
                transform: rotate(0.5);
            }
          }

        &.hidden {

            &:hover{
                box-shadow: none;
            }

            position: absolute;
            pointer-events: none;
            letter-spacing: 0.2em;
            animation-delay: 0.3s;
            animation-duration: 1s;
            animation-timing-function: ease-out;
            animation-name: fadeOut;
            animation-fill-mode: forwards;
        }
    }

    .line {
        max-width: 32em;
        opacity: 0;
        position: absolute;
        pointer-events: none;
        transition: opacity 2s ease-in, transform 2s ease-in-out;
        
        &:first-of-type {
            transform: translate(0,1em);
        }

        &.visible {
            position: relative;
            opacity: 1;
            transform: none;
        }

        &.ended {
            position: relative;
            opacity: 0;
            transform: none;
        }
    }
}