#container[data-theme="dark"] {
  --color-text: #ecf0f1;
  --color-text-opposite: #111;
  --color-text-rgb: 236, 240, 241;
  --color-text-darker: #d8d2cb;
  --color-text-darker-rgb: 216, 210, 203;
  --primary-color: #398ab9;
  --primary-color-rgb: 57, 138, 185;
  --primary-color-darker: #1c658c;
  --primary-color-darker-rgb: 28, 101, 140;
  --background: #0d1117;
  --background-rgb: 13, 17, 23;
  --foreground: #161b22;
  --foreground-rgb: 22, 27, 34;
  --random-color-border: transparent;
}

#container {
  --color-text: #141414;
  --color-text-opposite: #cdcdcd;
  --color-text-rgb: 20, 20, 20;
  --color-text-darker: #323232;
  --color-text-darker-rgb: 50, 50, 50;
  --primary-color: #398ab9;
  --primary-color-darker: #1c658c;
  --background: #fff;
  --foreground: #fff;
  --foreground-rgb: 255, 255, 255;
  --background-rgb: 255, 255, 255;
  --random-color-border: rgba(var(--color-text-rgb), .3);
  --random-color-empty: transparent;
  --random-color-0: #2cbb9c;
  --random-color-0-rgb: 44, 187, 156;
  --random-color-1: #39cb75;
  --random-color-1-rgb: 57, 203, 117;
  --random-color-2: #3d9ad9;
  --random-color-2-rgb: 61, 154, 217;
  --random-color-3: #9a5bb3;
  --random-color-3-rgb: 154, 91, 179;
  --random-color-4: #35495d;
  --random-color-4-rgb: 53, 73, 93;
  --random-color-5: #f0c129;
  --random-color-5-rgb: 240, 193, 41;
  --random-color-6: #e57d29;
  --random-color-6-rgb: 229, 125, 41;
  --random-color-7: #ecf0f1;
  --random-color-7-rgb: 236, 240, 241;
  --random-color-8: #96a5a5;
  --random-color-8-rgb: 150, 165, 165;
  --random-color-9: #d63031;
  --random-color-9-rgb: 214, 48, 49;
  --eruption-color-rgb: var(--random-color-9-rgb);
  --eruption-color-text: var(--random-color-9-text);
  --impact-color-rgb: var(--random-color-5-rgb);
  --impact-color-text: var(--random-color-5-text);
  --random-color-light: #f5f5f5;
  --random-color-dark: #0a0a0a;
  --random-color-empty: var(--color-text);
  --random-color-0-text: var(--random-color-dark);
  --random-color-1-text: var(--random-color-dark);
  --random-color-2-text: var(--random-color-light);
  --random-color-3-text: var(--random-color-light);
  --random-color-4-text: var(--random-color-light);
  --random-color-5-text: var(--random-color-dark);
  --random-color-6-text: var(--random-color-dark);
  --random-color-7-text: var(--random-color-dark);
  --random-color-8-text: var(--random-color-dark);
}

.random-colored-0 {
  background-color: var(--random-color-0);
  color: var(--random-color-0-text);
}

.random-colored-1 {
  background-color: var(--random-color-1);
  color: var(--random-color-1-text);
}

.random-colored-2 {
  background-color: var(--random-color-2);
  color: var(--random-color-2-text);
}

.random-colored-3 {
  background-color: var(--random-color-3);
  color: var(--random-color-3-text);
}

.random-colored-4 {
  background-color: var(--random-color-4);
  color: var(--random-color-4-text);
}

.random-colored-5 {
  background-color: var(--random-color-5);
  color: var(--random-color-5-text);
}

.random-colored-6 {
  background-color: var(--random-color-6);
  color: var(--random-color-6-text);
}

.random-colored-7 {
  background-color: var(--random-color-7);
  color: var(--random-color-7-text);
}

.random-colored-8 {
  background-color: var(--random-color-8);
  color: var(--random-color-8-text);
}

#intro {
  background-color: var(--background);
  z-index: 10;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  font-size: 1.5em;
  transition: opacity 1s;
  display: flex;
  position: absolute;
  inset: 0;
}

#intro.finished {
  opacity: 0;
  pointer-events: none;
}

#intro #mute-button {
  position: absolute;
  bottom: 1em;
  right: 1em;
}

#intro #skip-button {
  font-size: .8em;
  position: absolute;
  bottom: 1em;
  left: 50%;
}

#intro #play {
  background-color: var(--foreground);
  color: inherit;
  letter-spacing: .1em;
  cursor: pointer;
  border: none;
  border-radius: .5em;
  outline: none;
  padding: .5em 1em;
  font-size: x-large;
  transition-property: box-shadow, padding, letter-spacing;
  transition-duration: .3s;
}

#intro #play:active {
  border-color: var(--primary-color);
  border-style: solid;
  border-width: .1em;
}

#intro #play:hover {
  background-color: var(--background);
  border-color: var(--primary-color);
  letter-spacing: .2em;
  box-shadow: 0 0 3em 0em var(--primary-color), 0 0 6em 0em rgba(var(--primary-color-darker-rgb), .5);
  border-style: solid;
  border-width: 1px;
  padding: .5em 2em;
}

@keyframes fadeOut {
  to {
    opacity: 0;
    box-shadow: none;
    transform: rotate(.5);
    padding: 1em 3em;
  }
}

#intro #play.hidden {
  pointer-events: none;
  letter-spacing: .2em;
  animation-name: fadeOut;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-delay: .3s;
  animation-fill-mode: forwards;
  position: absolute;
}

#intro #play.hidden:hover {
  box-shadow: none;
}

#intro .line {
  max-width: 32em;
  opacity: 0;
  pointer-events: none;
  transition: opacity 2s ease-in, transform 2s ease-in-out;
  position: absolute;
}

#intro .line:first-of-type {
  transform: translate(0, 1em);
}

#intro .line.visible {
  opacity: 1;
  position: relative;
  transform: none;
}

#intro .line.ended {
  opacity: 0;
  position: relative;
  transform: none;
}

.button {
  cursor: pointer;
}

/*# sourceMappingURL=index.e558b257.css.map */
